import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIconButton = ({ loading, children, ...props }) => (
  <IconButton {...props} disabled={loading}>
    {loading ? <CircularProgress size={24} /> : children}
  </IconButton>
);

LoadingIconButton.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

LoadingIconButton.defaultProps = {
  loading: false,
};

export default LoadingIconButton;
